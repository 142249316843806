.error-container {
  max-width: 980px;
  margin: 0 auto;
  padding: 0; }
  @media (min-width: 992px) {
    .error-container {
      padding: 0 5%; } }
  .error-container .error-header {
    font-size: 25px;
    text-transform: uppercase;
    color: #fff;
    background-color: #000;
    height: 100px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: "montserrat-bold", sans-serif; }
    @media (min-width: 769px) {
      .error-container .error-header {
        font-size: 40px;
        height: 150px; } }
  .error-container .text-container {
    background-color: #f4f4f4;
    margin: 20px 0;
    height: 250px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    @media (min-width: 769px) {
      .error-container .text-container {
        margin: 20px 30px; } }
    @media (min-width: 992px) {
      .error-container .text-container {
        margin: 40px 50px; } }
    .error-container .text-container p {
      text-align: center; }
      .error-container .text-container p:first-of-type {
        font-size: 22px;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-family: "montserrat-semi-bold", sans-serif; }
        @media (min-width: 769px) {
          .error-container .text-container p:first-of-type {
            font-size: 27px; } }
      .error-container .text-container p:nth-of-type(2) {
        font-size: 16px;
        padding-bottom: 30px;
        border-bottom: 1px solid #000;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-family: "montserrat-semi-bold", sans-serif; }
        @media (min-width: 769px) {
          .error-container .text-container p:nth-of-type(2) {
            font-size: 19px;
            padding-bottom: 40px; } }
      .error-container .text-container p:nth-of-type(3), .error-container .text-container p:nth-of-type(4) {
        font-size: 13px;
        margin-bottom: 8px;
        line-height: 18px; }
        @media (min-width: 769px) {
          .error-container .text-container p:nth-of-type(3), .error-container .text-container p:nth-of-type(4) {
            line-height: 25px;
            margin-bottom: 0; } }
  .error-container .buttons-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0 0 30px; }
    @media (min-width: 769px) {
      .error-container .buttons-container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        margin: 0 30px 30px; } }
    @media (min-width: 992px) {
      .error-container .buttons-container {
        margin: 0 50px 50px; } }
    .error-container .buttons-container a {
      width: 100%;
      margin-bottom: 10px;
      -webkit-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
      letter-spacing: 1px;
      outline: none;
      text-transform: uppercase;
      padding: 8px 20px;
      font-size: 14px;
      border-radius: 0;
      text-align: center;
      background-color: #fff;
      color: #363332;
      border: 1px solid #363332;
      font-family: "montserrat-bold", sans-serif; }
      .error-container .buttons-container a:hover {
        background-color: #4e4e4e;
        color: #fff;
        text-decoration: none; }
      @media (min-width: 769px) {
        .error-container .buttons-container a {
          white-space: nowrap;
          padding: 8px 0;
          width: 30%;
          margin-bottom: 0; } }
  .error-container .error-header-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: #000;
    padding: 30px 2%;
    margin-top: 20px; }
    .error-container .error-header-container h1 {
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      margin-bottom: 20px;
      font-family: "montserrat-bold", sans-serif; }
      @media (min-width: 992px) {
        .error-container .error-header-container h1 {
          font-size: 34px; } }
    .error-container .error-header-container p {
      font-size: 10px;
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      margin-bottom: 0;
      line-height: 18px;
      font-family: "montserrat-regular", sans-serif; }
      .error-container .error-header-container p:not(:first-of-type) {
        margin-top: 6px; }
      @media (min-width: 992px) {
        .error-container .error-header-container p {
          margin-bottom: 6px;
          font-size: 13px;
          line-height: 24px; }
          .error-container .error-header-container p:not(:first-of-type) {
            margin-top: 0; } }
  .error-container .image-container {
    text-align: center;
    margin: 20px 0;
    height: 200px; }
    @media (min-width: 992px) {
      .error-container .image-container {
        height: auto; } }
    .error-container .image-container img {
      height: 100%; }

.contact-error-container {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 800px;
  margin: 30px auto 0; }
  .contact-error-container.mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #f2f2f2; }
    @media (min-width: 769px) {
      .contact-error-container.mobile {
        display: none; } }
    .contact-error-container.mobile .contact-card {
      text-align: center;
      width: 50%;
      margin: 30px 0;
      padding: 20px 0; }
      .contact-error-container.mobile .contact-card:nth-of-type(2n) {
        border-left: 1px solid #848281; }
      .contact-error-container.mobile .contact-card h4 {
        font-size: 12px;
        margin-bottom: 15px;
        font-family: "montserrat-bold", sans-serif; }
      .contact-error-container.mobile .contact-card img {
        height: 48px;
        width: auto; }
        .contact-error-container.mobile .contact-card img.ig {
          height: 40px;
          margin: 4px 0; }
        .contact-error-container.mobile .contact-card img + h4 {
          margin-top: 10px; }
      .contact-error-container.mobile .contact-card p {
        font-size: 12px;
        line-height: 20px;
        color: #333;
        margin-bottom: 0;
        font-family: "montserrat-semi-bold", sans-serif; }
        .contact-error-container.mobile .contact-card p.info {
          font-family: "montserrat-regular", sans-serif; }
        .contact-error-container.mobile .contact-card p.phone {
          margin-top: 10px; }
      .contact-error-container.mobile .contact-card a {
        display: block;
        color: #333;
        font-family: "montserrat-semi-bold", sans-serif; }
        .contact-error-container.mobile .contact-card a:hover {
          color: black; }
  .contact-error-container.desktop {
    display: none; }
    @media (min-width: 769px) {
      .contact-error-container.desktop {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
    .contact-error-container.desktop .contact-card {
      width: calc(50% - 5px);
      height: 100%;
      margin-bottom: 10px; }
      .contact-error-container.desktop .contact-card h4 {
        background-color: #f4f4f4;
        text-transform: uppercase;
        color: #333;
        font-size: 16px;
        letter-spacing: 1px;
        margin-bottom: 10px;
        padding: 20px 30px;
        font-family: "montserrat-bold", sans-serif; }
      .contact-error-container.desktop .contact-card .info-container {
        background-color: #f1e9e6;
        padding: 15px 30px;
        height: 100%;
        min-height: 120px; }
        .contact-error-container.desktop .contact-card .info-container p {
          color: #383638;
          letter-spacing: 0.5px;
          font-size: 13px;
          line-height: 20px;
          margin-bottom: 0;
          font-family: "montserrat-semi-bold", sans-serif; }
          .contact-error-container.desktop .contact-card .info-container p.phone {
            font-size: 18px; }
          .contact-error-container.desktop .contact-card .info-container p.time {
            font-size: 14px;
            letter-spacing: 1px; }
        .contact-error-container.desktop .contact-card .info-container .link {
          color: #383638;
          font-size: 13px;
          display: block;
          line-height: 20px;
          letter-spacing: 0.5px;
          text-decoration: underline;
          font-family: "montserrat-semi-bold", sans-serif; }
          .contact-error-container.desktop .contact-card .info-container .link:hover {
            color: #000; }
